import { Box, Typography } from "@mui/material";
import React from "react";
import { PageProps } from "gatsby";
import Layout from "../components/Layout";
import bricks from "../images/connect/bricks.svg";
import { MetaData } from "../components/common/meta";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/style.css";

const postStyle = {
  maxWidth: "1000px",
  mx: "auto",
  py: { xs: 8, md: 15 },
};

const Photography = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData
        title="Photography and Videography Consent"
        location={location}
        type="website"
      />
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            top: 0,
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
        <Box component="div" className="postStyle" sx={postStyle}>
          <Box
            component="div"
            sx={{
              maxWidth: 800,
              mx: "auto",
              mt: 4,
              mb: 6,
              textAlign: "center",
            }}
          >
            <Typography variant="h1">
              Web3 Gaming Week Personal Appearance and Copyright Consent
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              backgroundColor: "#091A3D",
              border: "1px solid #4B608D",
              px: { xs: 3, md: 5 },
              py: { xs: 3, md: 6 },
            }}
          >
            <Box component="section">
              <Typography sx={{ mb: 3 }}>
                This Personal Appearance and Copyright Consent (this “Consent”)
                is made in favor of Jump Operations, LLC and its affiliates
                (collectively, the “Hosts”) and the individual (the “Guest”).
              </Typography>
              <Typography sx={{ mb: 3 }}>
                The Guest hereby grants to the Hosts, and their successors,
                licensees, assignees, or any such other parties as the Hosts may
                designate, the rights discussed herein for use in connection
                with the creation of materials, including but not limited to
                audiovisual work, created from the Guest’s appearance at Web3
                Gaming Week (the “Work”), taking place from June 12, 2023
                through June 17, 2023 at Google Singapore, 70 Pasir Panjang Rd,
                #03-71 Mapletree Business City II, Singapore 117371.
              </Typography>
              <Typography sx={{ mb: 3 }}>
                The Guest irrevocably grants to the Hosts the rights to the
                below (the “Rights”):
              </Typography>
              <Typography>
                (a) record, photograph, visually identify or otherwise use,
                reproduce and edit the Guest’s voice, performance, statements,
                appearance, likeness, photograph, name, company/company name,
                and/or biography (collectively, the Guest’s “Appearance”) on
                audio recording, video recording, digital format, or still
                photography in connection with the Work (collectively, the
                “Recording”); <br />
                (a) record, photograph, visually identify or otherwise use,
                reproduce and edit the Guest’s voice, performance, statements,
                appearance, likeness, photograph, name, company/company name,
                and/or biography (collectively, the Guest’s “Appearance”) on
                audio recording, video recording, digital format, or still
                photography in connection with the Work (collectively, the
                “Recording”); <br />
                (b) own the Recording exclusively as a work made for hire for
                copyright purposes as understood by the US Copyright Act 17
                U.S.C. Section 101 (as the Recording is specially ordered or
                commissioned by the Hosts for use as part of the Work);
                <br />
                (c) incorporate the Appearance and/or the Recording into the
                Work as the Hosts may see fit at the Hosts’ sole discretion,
                including in advertising, marketing, promotion and/or publicity
                in connection therewith; and <br />
                (d) use, exhibit, distribute, transmit and/or otherwise exploit
                the Appearance and/or the Recording, edited or altered in as
                many versions the Hosts may see fit at the Hosts’ sole
                discretion, in connection with the Work or in any related or
                derivative versions of the Work, in perpetuity and throughout
                the universe.
              </Typography>
              <Typography sx={{ mb: 3 }}>
                This Consent does not require the Hosts to make or distribute or
                exhibit the Work, or to use the Appearance in connection with
                the Work. No compensation, monetary or otherwise, will be
                payable to the Guest at any time in connection with the subject
                matter of this Consent. The Guest shall have no right of
                consultation or right of approval in connection with the Rights,
                the Appearance, the Work, or any subject matter of this Consent.
              </Typography>
              <Typography sx={{ mb: 3 }}>
                The Guest’s grant of the Rights will not conflict with or
                violate any commitment, agreement or understanding the Guest has
                or will have with any person or entity for non-infringement,
                non-violation, or non-breach of any right or obligation.
              </Typography>
              <Typography sx={{ mb: 3 }}>
                YOUR CONSENT PROVIDES THE HOSTS WITH YOUR CONSENT, WAIVER, AND
                RELEASE OF LIABILITY (INCLUDING BUT NOT LIMITED TO CLAIMS FOR
                COPYRIGHT INFRINGEMENT, DEFAMATION, OR INVASION OF THE RIGHTS OF
                PRIVACY OR PUBLICITY), ALLOWING THE HOSTS TO PUBLICIZE AND
                COMMERCIALLY EXPLOIT YOUR NAME, LIKENESS, AND OTHER PERSONAL
                CHARACTERISTICS AND PRIVATE INFORMATION AS SET OUT ABOVE.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <StaticImage
        src="../images/index/lines.svg"
        alt=""
        placeholder="blurred"
        style={{
          position: "absolute",
          right: "0",
          bottom: "80px",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default Photography;
